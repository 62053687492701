
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";

export default defineComponent({
  name: "updatePermission",
  components: {},
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Update Permission", [
        "UsersManagement",
        "Roles",
        "Roles View",
      ]);
    });

    return {};
  },
});
