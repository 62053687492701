
import { defineComponent, onMounted, ref } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
import KTUpdatePermission from "@/views/main/userManagement/modals/UpdatePermission.vue";
import KTPlatformGroup from "@/views/main/userManagement/modals/PlatformGroup.vue";
import KTUserGroup from "@/views/main/userManagement/modals/UserGroup.vue";

export default defineComponent({
  name: "allPlatforms",
  components: { KTUpdatePermission, KTPlatformGroup, KTUserGroup },
  props: {
    widgetClasses: String,
  },
  setup() {
    const isUserGroup = ref(false);
    const isPlatformGroup = ref(true);
    const isPermission = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Roles View", ["Users Management", "Roles"]);
    });

    const handleUser = () => {
      isUserGroup.value = true;
      isPlatformGroup.value = false;
      isPermission.value = false;
    };

    const handlePlatform = () => {
      isUserGroup.value = false;
      isPlatformGroup.value = true;
      isPermission.value = false;
    };

    const handlePermission = () => {
      isUserGroup.value = false;
      isPlatformGroup.value = false;
      isPermission.value = true;
    };

    return {
      handlePermission,
      handlePlatform,
      handleUser,
      isPermission,
      isPlatformGroup,
      isUserGroup,
      KTUpdatePermission,
      KTPlatformGroup,
      KTUserGroup,
    };
  },
});
