
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
export default defineComponent({
  name: "Roles",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Platform Group", [
        "UsersManagement",
        "Roles",
        "Roles View",
      ]);
    });

    return {};
  },
});
