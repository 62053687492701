import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "post d-flex flex-column-fluid",
  id: "kt_post"
}
const _hoisted_2 = {
  id: "kt_content_container",
  class: "container-xxl"
}
const _hoisted_3 = { class: "d-flex flex-column flex-xl-row" }
const _hoisted_4 = { class: "flex-column flex-lg-row-auto w-100 w-lg-300px mb-10" }
const _hoisted_5 = { class: "card card-flush" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "mb-0" }
const _hoisted_9 = { class: "card-footer flex-wrap pt-0" }
const _hoisted_10 = {
  key: 0,
  class: "flex-lg-row-fluid ms-lg-10"
}
const _hoisted_11 = {
  key: 1,
  class: "flex-lg-row-fluid ms-lg-10"
}
const _hoisted_12 = {
  key: 2,
  class: "flex-lg-row-fluid ms-lg-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTPlatformGroup = _resolveComponent("KTPlatformGroup")!
  const _component_KTUserGroup = _resolveComponent("KTUserGroup")!
  const _component_KTUpdatePermission = _resolveComponent("KTUpdatePermission")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t("developer")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["btn btn-light my-1 me-1", [_ctx.isPlatformGroup && 'btn-light-primary']]),
                "data-bs-toggle": "modal",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlePlatform && _ctx.handlePlatform(...args)))
              }, _toDisplayString(_ctx.$t("totalPlatforms")), 3),
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["btn btn-light my-1 me-1", [_ctx.isUserGroup && 'btn-light-primary']]),
                "data-bs-toggle": "modal",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleUser && _ctx.handleUser(...args)))
              }, _toDisplayString(_ctx.$t("totalUsers")), 3),
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["btn btn-light my-1 me-1", [_ctx.isPermission && 'btn-light-primary']]),
                "data-bs-toggle": "modal",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handlePermission && _ctx.handlePermission(...args)))
              }, _toDisplayString(_ctx.$t("editPermission")), 3)
            ])
          ])
        ]),
        (_ctx.isPlatformGroup)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_KTPlatformGroup)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isUserGroup)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_KTUserGroup)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isPermission)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_KTUpdatePermission)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}